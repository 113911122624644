@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');
/*@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300');*/

html body  {
  margin: 0;
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/*font-family: "Ojuju", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/